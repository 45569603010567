class NewsletterBannerCard extends BATComponent {
	
	beforeLoad() {
		//
	}

	afterLoad() {

		this.$el = $(this);
		
		window.addEventListener('login-action', () => {
			this.setAccount();
		});
		this.setAccount();

		$.each(this.$el.find('button[data-parameters]'), (index, button) => {
			const buttonType = $(button).data('cta-type');

			switch (buttonType) {
				case 'modal':
					// modal button click
					$(button).click(() => {
						const targetId = $(button)
							.data('parameters')
							.replace('#', '');
						$(`#${targetId}`)
							.addClass('active')
							.trigger('modal:open');
					});
					break;
				default:
					break;
			}
		});

		

	}



	async setAccount() {
		
		this.data.loggedIn = Utils.getCookie('commerce-auth-verify') === 'true';
		const $newsletterBanner = this.$el.find('.bat-card-gloitnewsletterbanner');
		const wasClosed = Utils.getCookie('newsLetterBannerClosed') == 'true';
		const isAuthorMode = $('body').hasClass('authorMode');
		let icon = document.querySelector('.icon');
		let banner = document.querySelector('.bat-card-gloitnewsletterbanner');
		
		if(this.data.loggedIn){
			const requestCustomerDetails = await Commerce.getCustomerDetails();
			const customer = requestCustomerDetails.data.customer ? requestCustomerDetails.data.customer : '';
			if(icon){
				icon.addEventListener('click', (event) => {
					event.preventDefault();
					event.stopPropagation();
					banner.classList.add('close');
					Utils.setCookie('newsLetterBannerClosed', 'true');
					const closeBannerEvent = new CustomEvent('closeBannerEvent', { detail: {wasClosed:true} });
					window.dispatchEvent(closeBannerEvent);
					if(!isAuthorMode && this.data.loggedIn){
						if(customer && customer.global_loyalty_optin === '0') {
							if(window.tfsx2_PopupDeviceRegisterIntent_show) {
								window.tfsx2_PopupDeviceRegisterIntent_show(); 
							}
						}else if(customer && customer.global_loyalty_optin === '2') {
							if(window.tfsx2_PopupHogRegisterIntent_show) {
								window.tfsx2_PopupHogRegisterIntent_show(); 
							}
						}
					}else{
						if (window.tfsx2_PopupRegisterIntent_show) {
							window.tfsx2_PopupRegisterIntent_show();
						}
					}
				})
			}	
			if(!wasClosed){
				if(!isAuthorMode && this.data.loggedIn){
					
					if(customer && customer.global_loyalty_optin === '0') {
						document.querySelector(".bat-card-gloitnewsletterbanner").style.background="#20B0C4";
						document.querySelector(".bat-card-gloitnewsletterbanner-title .title").innerHTML="Vantaggi esclusivi per te!";
						document.querySelector(".bat-card-gloitnewsletterbanner p a.bat-cta-style.arrow-link-dark").innerHTML="Registra il tuo dispositivo";
						document.querySelector(".bat-card-gloitnewsletterbanner-cta img").src="/content/dam/glo-it/images/VOUCHER ICON.png"
						document.querySelector(".bat-card-gloitnewsletterbanner .arrow-link a").href="/it/it/myaccount/register-device/index"					
						$newsletterBanner.removeClass('close');

					}else if(customer && customer.global_loyalty_optin === '2') {
						document.querySelector(".bat-card-gloitnewsletterbanner").style.background="linear-gradient(90deg, #0D124F 0%, #000AE3 100%)";
						document.querySelector(".bat-card-gloitnewsletterbanner").style.transform ="rotate(180deg);"
						document.querySelector(".bat-card-gloitnewsletterbanner-title .title").innerHTML="Entra in House of gloers!";
						document.querySelector(".bat-card-gloitnewsletterbanner-title .title").style.fontWeight="550"
						document.querySelector(".bat-card-gloitnewsletterbanner-title .title").style.letterSpacing="1px"
						document.querySelector(".bat-card-gloitnewsletterbanner p a.bat-cta-style.arrow-link-dark").innerHTML="Iscriviti ora";
						document.querySelector(".bat-card-gloitnewsletterbanner  p a.bat-cta-style.arrow-link-dark").style.color="#ED7D30";
						document.querySelector(".bat-card-gloitnewsletterbanner  p a.bat-cta-style.arrow-link-dark").style.fontWeight="510"
						document.querySelector(".bat-card-gloitnewsletterbanner  p a.bat-cta-style.arrow-link-dark").style.letterSpacing="3px"
						document.querySelector(".bat-card-gloitnewsletterbanner .arrow-link").classList.add("arrow-link-orange")
						document.querySelector(".bat-card-gloitnewsletterbanner .arrow-link a").href="/it/it/myaccount/insiders-club"
						document.querySelector(".bat-card-gloitnewsletterbanner-cta img").src="/content/dam/glo-it/images/LOGO HOG.png"
						$newsletterBanner.removeClass('close');
						
					}else if(customer && customer.global_loyalty_optin === '1') {
						$newsletterBanner.addClass('close');
					}
				}else{
					$newsletterBanner.removeClass('close');
				}

				if (window.location.pathname.indexOf('qr-code-form') > -1) {
					$newsletterBanner.addClass('close');
				}
			}

		}
		else {
			if((Utils.getCookie('newsLetterBannerClosed') == null) || (!wasClosed)){
				$newsletterBanner.removeClass('close');
				if(icon){
					icon.addEventListener('click', (event) => {
						event.preventDefault();
						event.stopPropagation();
						banner.classList.add('close');
						Utils.setCookie('newsLetterBannerClosed', 'true');
						const closeBannerEvent = new CustomEvent('closeBannerEvent', { detail: {wasClosed:true} });
						window.dispatchEvent(closeBannerEvent);
						if (window.tfsx2_PopupRegisterIntent_show) {
							window.tfsx2_PopupRegisterIntent_show();
						}
					})
				}	
			}
			
		}
		
		
		$(this).click(() => {
			if(this.data.loggedIn){
				if(customer && customer.global_loyalty_optin === '0') {
					window.location.href="/it/it/myaccount/register-device/index"
				}else if(customer && customer.global_loyalty_optin === '2') {
					window.location.href="/it/it/myaccount/insiders-club"
				}
			}else{
				window.location.href="/it/it/registrazione-dispositivo#nwls"
			}
		})
		
	}
		
	renderComponent(data) {
		this.setAttribute('data-model', JSON.stringify(data));
	}

	beforeUpdate() {
		//
	}

	afterUpdate() {
	}

	unload() {
		//
	}
}

!customElements.get('bat-card-gloitnewsletterbanner') && customElements.define('bat-card-gloitnewsletterbanner', NewsletterBannerCard);
